<template>
	<div class="swiper-page">
		<div class="control-box flex flex-end mb20">
			<a-button type="primary" @click="toAdd">
				添加轮播图
			</a-button>
		</div>
		<div class="list">
			<div class="swiper-item" v-for="(item,index) in list" :key="index">
				<!-- <div class="img-box" :style="{'backgroundImage': 'url('+$imglink+item.img_id+')'}"> -->
				<div class="img-box">
					<img :src="$imglink+item.img_id" >
				</div>
				<div class="bottom">
					<div class="tl">标题:{{item.title}}</div>
					<div class="link">链接:{{item.url}}</div>
					<div class="control flex flex-end">
						<a-button size="small" type="primary" style="margin-right: 10px;" @click="toEdit(item.lun_id)">修改</a-button>
						<a-popconfirm title="确认删除该图片吗" ok-text="是" cancel-text="否" @confirm="deleteUser(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {list:[]}
		},
		computed: {},
		created() {
			
		},
		mounted() {
			this.getData()
		},
		methods:{
			toAdd() {
				this.$router.push({path:'/system/addSwiper'})
			},
			toEdit(id) {
				console.log(id)
				this.$router.push({path:'/system/addSwiper',query:{lun_id: id}}) 
			},
			deleteUser(item) {
				let t = this
				t.$get({
					port: 'a',
					url: 'delLunbo',
					data: {lun_id: item.lun_id}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						this.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			getData() {
				let t = this
				t.$get({
					port: 'a',
					url: 'lunboList',
					data: {type: 1}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			}
		}
	}
</script>

<style lang="less">
	.swiper-page {
		.list {
			display: flex;
			flex-wrap: wrap;
		}
		.swiper-item {
			width: 300px;
			margin-right: 20px;
			border: 1px solid #eee;
			margin-bottom: 20px;
			.img-box {
				height: 180px;
				width: 100%;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				img{
					width: 100%;
					height: 100%;
					display: block;
					border: none;
					object-fit: cover;
				}
				&::after{
					display: none;
				}
			}
			.bottom {
				padding: 10px 14px;
				border-top: none;
				.tl {
					font-size: 14px;
					line-height: 28px;
				}
				.link {
					font-size: 12px;
					line-height: 20px;
					height: 40px;
					overflow: hidden;
				}
				.control {
					padding-top: 10px;
				}
			}
		}
	}
</style>
